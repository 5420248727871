import { useState, useEffect } from 'react';

export function useResponsive() {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
  });

  useEffect(() => {
    let resizeTimeout:any;

    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        // console.log('resize')
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 1000);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
        clearTimeout(resizeTimeout);
      };
    }
  }, []);

  const isDesktop = windowSize.width >= 1200;
  const isTablet = windowSize.width > 820 && windowSize.width < 1200;
  const isMobile = windowSize.width <= 820;
  const isSmallTablet = windowSize.width > 567 && windowSize.width < 1200;

  return {
    isDesktop,
    isTablet,
    isMobile,
    isSmallTablet,
  };
}
