declare global {
  interface Window {
      dataLayer: any;
  }
}
export default function handleSelectContent(
  blockData: any,
  cardPosition: number | undefined,
  pathName: string
) {
  const re = /(\/videos|episodios)(\/[a-z0-9-]+\/)$/;
  if (typeof window.dataLayer !== "undefined") {
      const eventData = {
          clickBloque: Number.isInteger(cardPosition) ? `${cardPosition! + 1}` : undefined,
          colorBloque: blockData?.colorBloque,
          contenidosBloque: blockData?.contenidosBloque,
          content_name: blockData?.content_name ?? ``,
          content_type: `${blockData?.content_type}`,
          count_actualizacion: blockData?.count_actualizacion
              ? `${blockData?.count_actualizacion}`
              : undefined,
          countWidget: blockData?.countWidget
              ? `${blockData?.countWidget}`
              : undefined,
          descripcionBloque: blockData?.descripcionBloque,
          estiloBloque: blockData?.estiloBloque,
          event: "select_content",
          eventAction: blockData?.eventAction,
          posicionBloque: blockData?.posicionBloque
              ? `${blockData?.posicionBloque}`
              : undefined,
          section: `${pathName.replace(re, "/")}`,
          subBloque: blockData?.subBloque,
          tamanoBloque: blockData?.tamanoBloque,
          tipoBloque: blockData?.tipoBloque,
          tipoElemento: blockData?.tipoElemento,
          tituloBloque: blockData?.tituloBloque,
      };
      window.dataLayer.push(eventData);
  }
}