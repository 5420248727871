import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import ReactDOM from "react-dom";
import { ProfileInfo } from "@/app/components/organisms/BlockProfileSP/interfaces/ProfileSP.interface";
import profile from "@/app/styles/Molecules/StoryPage.module.scss";
import style from "@/app/styles/Molecules/CardStoryPage.module.scss";
import MediaStoryPage from "@/app/components/molecules/MediaStoryPage/MediaStoryPage";
import StoryPageHeadline from "@/app/components/molecules/StoryPageHeadline/StoryPageHeadline";
const StoryPageBody = dynamic(
	() => import("@/app/components/molecules/StoryPageBody/StoryPageBody")
);
const BreakingMolecule = dynamic(
	() => import("@/app/components/molecules/BreakingMolecule/BreakingMolecule")
);
const SocialShareButtons = dynamic(
	() =>
		import("@/app/components/molecules/SocialShareButtons/SocialShareButtons")
);

type Props = {
	cardData?: any;
	cardsArray?: any[];
	isVariantCoverage?: boolean;
	isVariantBulletin?: any;
	profileData?: any;
	type?: string;
	position?: Number;
	total?: Number;
	adOps?: {
		hasTeads: boolean;
		hasSeedTag: boolean;
	};
};

export default function CardStoryPage({
	cardData,
	isVariantBulletin = false,
	profileData,
	type,
	position,
	total,
	adOps,
}: Props) {
	ReactDOM.preconnect("https://platform.twitter.com/", {
		crossOrigin: "anonymous",
	});
	ReactDOM.prefetchDNS("https://platform.twitter.com/");
	ReactDOM.preconnect("https://i.ytimg.com/", { crossOrigin: "anonymous" });
	ReactDOM.prefetchDNS("https://i.ytimg.com/");
	ReactDOM.preconnect("https://cdn.ampproject.org/", {
		crossOrigin: "anonymous",
	});
	ReactDOM.prefetchDNS("https://cdn.ampproject.org/");
	ReactDOM.preconnect("https://connect.facebook.net/", {
		crossOrigin: "anonymous",
	});
	ReactDOM.prefetchDNS("https://connect.facebook.net/");

	const {
		title = null,
		editorialTitle = null,
		summary = null,
		body = null,
		path = null,
	} = cardData ?? {};

	return (
		<>
			<article className={`${style["cardStoryPage__wrapper"]}`}>
				{cardData?.isBreakingStoryPage ? (
					<BreakingMolecule cardData={cardData} isVariantCoverage={true} />
				) : (
					<StoryPageHeadline cardItem={cardData} />
				)}
				<MediaStoryPage
					isOpening={true}
					cardItem={cardData}
					isVariantBulletin={isVariantBulletin}
				/>
				<SocialShareButtons
					title={editorialTitle || title}
					description={summary}
					link={path}
					isVariantStoryPage={true}
				/>
				<StoryPageBody
					bodyHtml={body}
					cardItem={cardData}
					type={type}
					position={position}
					total={total}
					hasButton
					adOps={adOps}
				/>
				{profileData && (
					<aside
						className={`${profile["profile__aside"]} ${profile["profile__mobile"]}`}
					>
						{profileData?.getStoryPages?.profileInfo?.map(
							(info: ProfileInfo, index: number) => (
								<div
									key={info.title}
									className={`${profile[`profile__mobile-${index}`]} ${
										profile["profile__cardInfo"]
									}`}
								>
									<h2 className={profile["profile__title"]}>{info.title}</h2>

									<div className={profile["profile__wrapperInfo"]}>
										{Object.entries(info).map(
											([fieldName, field]) =>
												field.text && (
													<p
														key={fieldName}
														className={profile["profile__info"]}
													>
														{field.text.includes("https") ? (
															<Link
																href={field.text ?? ""}
																className={profile["profile__urlSocial"]}
															>
																{field.label}
															</Link>
														) : (
															<span className={profile["profile__dataTitle"]}>
																{field.label}
															</span>
														)}

														{field.text.includes("https") ? (
															<></>
														) : (
															<>: {field.text}</>
														)}
													</p>
												)
										)}
									</div>
									{info?.img?.url && (
										<div
											className={`${profile["profile__mobile__imageWrapper"]}`}
										>
											<Image
												alt={info.img.alt || "nmas"}
												src={info.img.url ?? "/assets/placeholder_.svg"}
												height={100}
												width={306}
												loading="lazy"
												placeholder="blur"
												blurDataURL={`${
													process.env.NEXT_PUBLIC_BLURIMAGE ||
													"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
												}`}
												className={profile["profile__mobile-image"]}
											/>
										</div>
									)}
								</div>
							)
						)}
					</aside>
				)}
			</article>
		</>
	);
}
