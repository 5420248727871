import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
	split,
	from,
} from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

export function configureClient({ initialState }: any) {
	const cache = new InMemoryCache({
		typePolicies: {
			AttributesVideoContent: {
				keyFields: ["path"],
			},
		},
	}).restore(initialState || {});

	const httpLink = new HttpLink({
		uri: process.env.NEXT_PUBLIC_APOLLO_SERVER_URL,
	});

	const wsLink = new GraphQLWsLink(
		createClient({
			url: `${process.env.NEXT_PUBLIC_APOLLO_SERVER_WS_URL}/ws`,
			shouldRetry: () => true,
			retryAttempts: 5,
			retryWait: async (attempt) => {
				console.log("attempt =>: ", attempt);
				console.log(
					"Retrying connection in ",
					(attempt * 1000) / 1000,
					" seconds"
				);
				await new Promise((resolve) => setTimeout(resolve, attempt * 1000));
			},
		})
	);

	const splitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === "OperationDefinition" &&
				definition.operation === "subscription"
			);
		},
		wsLink,
		httpLink
	);
	const ssrMode = true;

	return new ApolloClient({
		ssrMode,
		link: splitLink,
		cache,
	});
}

function globalErrorHandler(): import("@apollo/client/link/error").ErrorLink.ErrorHandler {
	return (error: ErrorResponse) => {
		if (error) {
			const { graphQLErrors } = error;
			graphQLErrors?.forEach((e) => {
				const code = e?.extensions?.code;
				const message = e?.message;
				console.log(code, message);
				if (message === "No se encontró la sección") {
					// if(context){
					// 	context.statusCode = 404;
					// }
				}
			});
		}
		// if(context){
		// 	context.statusCode = 500;
		// }
	};
}
