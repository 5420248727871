import { ECommerce, ECommerceItem } from "./interfaces/Ecommerce.interface";

declare global {
	interface Window {
		dataLayer: any;
	}
}
/**
 *
 * @param blockData The info of the eCommerce
 * @param pathName The path of the current event
 */

export default function handleSelectContentECommerce(
	blockData: ECommerceItem[],
	listId?: string,
	listName?: string,
	event?:string | undefined,
) {
	if (typeof window.dataLayer !== "undefined") {
		const eCommerceData: ECommerce = {
			event: event || undefined,
			ecommerce: {
				item_list_id: listId,
				item_list_name: listName,
				items: blockData,
			},
		};
		window?.dataLayer?.push(eCommerceData);
	}
}
