import dynamic from "next/dynamic";
import MultiVideoSlider from "../StoryPageMedia/Videos/MultiVideoSlider/MultiVideoSlider";
import style from "@/app/styles/Molecules/MediaStoryPage.module.scss";
const Icon = dynamic(() => import("@/app/components/atoms/Icon/Icon"));
const SingleImage = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Images/SingleImage/SingleImage"
		)
);
const MultiImage = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Images/MultiImage/MultiImage"
		)
);
const SingleVideo = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Videos/SingleVideo/SingleVideo"
		)
);
const MultiVideo = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Videos/MultiVideo/MultiVideo"
		)
);

type Props = {
	cardItem?: any;
	typeOfMedia?: string;
	aspect?: string;
	height?: string;
	isOpening?: boolean;
	noLowerDescription?: boolean;
	isVariantBulletin?: boolean;
	dataLayer?: any;
	showPagination?: boolean;
	showNavigation?: boolean;
	showZoomOption?: boolean;
	isLiveBlog?: boolean;
	isVertical?: boolean;
	isAlternativeVideos?: boolean;
	isPinned?: boolean;
	carouselMedia?: string;
};

export default function MediaStoryPage({
	typeOfMedia = "multiimage",
	aspect = "16/9",
	height,
	cardItem,
	isOpening,
	noLowerDescription = false,
	isVariantBulletin = false,
	dataLayer,
	isLiveBlog = false,
	showPagination = false,
	showNavigation = true,
	showZoomOption = false,
	isVertical = false,
	isAlternativeVideos = false,
	isPinned,
	carouselMedia,
}: Props) {
	const typeMedia =
		(cardItem?.videoStory && cardItem?.relatedVideos?.length === 0) ||
		(cardItem?.videos?.length === 1 && cardItem?.videos?.[0]?.cmsid !== null)
			? "singlevideo"
			: cardItem?.videoStory && cardItem?.relatedVideos?.length > 0
			? "multivideo"
			: isVariantBulletin
			? cardItem?.typeMedia ?? typeOfMedia
			: cardItem?.images?.filter((item: any) => item.image !== null)?.length ===
			  1
			? "singleimage"
			: cardItem?.images?.filter((item: any) => item.image !== null)?.length >=
			  2
			? "multiimage"
			: cardItem?.typeMedia || "singleimage";
	let mergedData;
	if (typeMedia === "multivideo") {
		mergedData = {
			multiVideos: [cardItem?.videoStory, ...cardItem?.relatedVideos],
		};
	}
	// TODO Single Image click Live Blog Data Layer

	return (
		<>
			<div
				className={`
				${style["media__mediaWrapper"]}
				${
					isLiveBlog && !isPinned
						? " " + style?.liveblog
						: `${style["media__mediaWrapper__liveblog--pinned"]}`
				}
				${
					typeMedia === "multiimage" && isLiveBlog
						? style["media__mediaWrapper--carousel"]
						: ""
				}
				${
					carouselMedia && carouselMedia === "multiVideo"
						? style["media__mediaWrapper--multiVideo"]
						: carouselMedia && carouselMedia === "multiImage"
						? style["media__mediaWrapper--multiImage"]
						: ""
				}
				`}
			>
				{typeMedia === "singleimage" && (
					<>
						<SingleImage
							cardItem={cardItem}
							aspect={aspect}
							height={height}
							isVariantBulletin={isVariantBulletin}
							isliveblog={isLiveBlog}
							isPinned={isPinned}
						/>
						{isVariantBulletin && cardItem?.file?.uri?.url && (
							<a
								rel="noreferrer"
								className={`${style["media__wrapperDownloadButton"]}`}
								target="_blank"
								href={cardItem?.file?.uri?.url}
								download={cardItem?.file?.filename}
							>
								<Icon name="file" />
								Descargar
							</a>
						)}
					</>
				)}
				{typeMedia === "multiimage" && (
					<>
						<MultiImage
							isOpening={isOpening}
							cardItem={cardItem}
							typeMedia={typeMedia}
							aspectRatio={aspect}
							showNavigation={showNavigation}
							showPagination={showPagination}
							showZoomOption={showZoomOption}
							isLiveBlog={isLiveBlog}
						/>
					</>
				)}
				{typeMedia === "singlevideo" && (
					<SingleVideo
						cardItem={cardItem?.videoStory || cardItem?.videos?.[0]}
						alterTitle={cardItem?.title}
						noLowerDescription={noLowerDescription}
						dataLayer={dataLayer}
						isVertical={isVertical}
						isLiveBlog={isLiveBlog}
					/>
				)}
				{typeMedia === "multivideo" && !isAlternativeVideos && (
					<MultiVideo
						isOpening={isOpening}
						cardItem={mergedData?.multiVideos}
					/>
				)}
				{typeMedia === "multivideo" && isAlternativeVideos && (
					<MultiVideoSlider
						cardItem={mergedData?.multiVideos}
						typeMedia={typeMedia}
						isLiveBlog={isLiveBlog}
					/>
				)}
			</div>
		</>
	);
}
