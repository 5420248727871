/** TextSanitizerOptions type */
type TextSanitizerOptions = {
	text?: string;
	isTruncated?: boolean;
	isTitle?: boolean;
	isLabel?: boolean;
	isUrl?: boolean;
	isShowEmptyPlaceholder?: boolean;
	isCapitalized?: boolean;
	isLongDescription?: boolean;
	isShortDescription?: boolean;
	maxCharacters?: number;
	customPlaceholder?: string;
	isShowEllipsis?: boolean;
};

/** true if all text sanitizers show an empty placeholder if text is null [FALSE by default] */
const isShowAllEmptyPlaceholders: boolean = false;

/** possible BaseCard types */
const TEXT_LENGTHS = {
	label: 12,
	title: 80,
	shortDescription: 140,
	longDescription: 999999,
};

/** default text placeholder */
const placeholder: string = "   ";

/**
 *
 * @param param [TextSanitizerOptions] TYPE with all the sanize text optional propertyes
 * - text: text to be parsed [default ' ']
 * - isTruncated: defines if text will be trucnated [default FALSE]
 * - isTitle = defines if text is title or not, applies [70 chars max] [default FALSE]
 * - isLabel = defines if text is label or not, applies [12 chars max] [default FALSE],
 * - isUrl = defines if text is url [applies '/' as placeholder] [default FALSE]
 * - isCapitalized =defines if text will hace forced UPPERCASE or not [default FALSE]
 * - isShortDescription = defines if text is short description or not, applies [140 chars max] [default FALSE]
 * - maxCharacters = defines max characters to truncate text [default 999999]
 * - customPlaceholder = defines custom placeholder for text if null [default Lorem Ipsum],
 * - isShowEmptyPlaceholder = defines placeholder as null if recieved text is null as well [default FALSE],
 * - isShowEllipsis = defines ellipsis  (...) for truncated text [default FALSE],
 * @returns sanitized text with all the given properties
 */
const sanitizeText = ({
	text = "",
	isTruncated = false,
	isTitle = false,
	isLabel = false,
	isUrl = false,
	isCapitalized = false,
	isShowEllipsis = false,
	isShortDescription = false,
	isShowEmptyPlaceholder = false,
	maxCharacters = TEXT_LENGTHS.longDescription,
	customPlaceholder = "",
}: TextSanitizerOptions): string => {
	let finalText: string = "";
	let finalPlaceholder: string = placeholder.slice();

	if (text) {
		finalText = text.slice();

		if (isCapitalized) {
			finalText = finalText.toUpperCase();
			finalPlaceholder = finalPlaceholder.toUpperCase();
		}

		if (isLabel) maxCharacters = TEXT_LENGTHS.label;
		if (isTitle) maxCharacters = TEXT_LENGTHS.title;
		if (isShortDescription) maxCharacters = TEXT_LENGTHS.shortDescription;
		if (maxCharacters !== TEXT_LENGTHS.longDescription) {
			finalText = finalText?.slice(0, maxCharacters);
			finalPlaceholder = placeholder?.slice(0, maxCharacters);
		}

		if (isTruncated) {
			finalText = finalText?.slice(0, maxCharacters);
			finalPlaceholder = finalPlaceholder?.slice(0, maxCharacters);
		}
		if (isShowEllipsis && isTruncated && text.length > maxCharacters + 3) {
			finalText = finalText + "";
			finalPlaceholder = finalPlaceholder + "";
		}
	}

	if (isUrl) finalPlaceholder = "/";
	if (customPlaceholder) finalPlaceholder = customPlaceholder;
	if (isShowEmptyPlaceholder) {
		finalPlaceholder = "";
	}
	if (isShowAllEmptyPlaceholders) finalPlaceholder = "";

	return finalText ? finalText : finalPlaceholder;
};

export default sanitizeText;
